import { renderDataCentreBlocks } from '../../util/BlockUtil';

const Metro = ({
  data,
  metros,
  lang,
  width,
  popular_downloads_posts,
  related_content,
  tags,
  global,
}) => {
  return (
    <div className="metro-template">
      {renderDataCentreBlocks({
        data,
        metros,
        lang,
        width,
        global,
        popular_downloads_posts,
        related_content,
        tags,
      })}
    </div>
  );
};
export default Metro;
